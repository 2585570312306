import React, { Component } from "react";

class Footer extends Component {
	render() {
		return (
			<footer>
				&copy; 2022 - Alex Spalvieri
			</footer>
		);
	}
}

export default Footer;
